@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.gradient-text {
    background: linear-gradient(270deg, #fa1515, #ffffff, #3ddd00);
    background-size: 200% 200%;
    animation: gradient-animation 5s ease infinite;
    -webkit-background-clip: text;
    color: transparent;
}
